/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import React from "react";
import { AnimatePresence } from "framer-motion";

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
  prevRouterProps,
}) => {
  const currentPosition = getSavedScrollPosition(location);

  if (
    prevRouterProps &&
    prevRouterProps.location.pathname !== location.pathname
  ) {
    setTimeout(() => {
      window.scrollTo(...(currentPosition || [0, 0]));
    }, 500);
  } else {
    window.scrollTo(...(currentPosition || [0, 0]));
  }

  return false;
};

export const wrapPageElement = ({ element }) => (
  <AnimatePresence mode="wait">{element}</AnimatePresence>
);
